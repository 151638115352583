import AOS from 'aos';

module.exports = function(){
    AOS.init({
        startEvent: 'DOMContentLoaded',
        duration: 600,
        disable: msieOrMobile,
        once: true
    });

    // Check if browser is Internet Explorer or mobile
    function msieOrMobile() {
    	var ua = window.navigator.userAgent;
		var isMsie = ua.indexOf("MSIE ") > 0 ? true : false;

		var isMobile = navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Windows Phone/i);

		if (isMsie || isMobile) {
		    return true;
		}
    }
};