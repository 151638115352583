/**
 * Scripts
 */

require('./components/accordion.js')();
require('./components/aos.js')();
require('./components/google-maps.js')();
require('./components/lazy-background.js')();
require('./components/mobile-menu.js')();
require('./components/modal.js')();
require('./components/smooth-scroll.js')();