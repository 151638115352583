module.exports = function(){
    function initMap() {
        var mapDiv = document.getElementById('map');
        var mapLat = parseFloat($('.js-map').data('lat'));
        var mapLng = parseFloat($('.js-map').data('lng'));
        var center = new google.maps.LatLng({lat: mapLat, lng: mapLng});

        var icon = {
            url: urls.assets + 'img/icon/map-marker.svg',
            scaledSize: new google.maps.Size(30, 45)
        }
        
        var map = new google.maps.Map(mapDiv, {
            zoom: 14,
            center: center,
            scrollwheel: false,
            styles: [{"featureType":"landscape","elementType":"all","stylers":[{"color":"#deeae6"}]},{"featureType":"poi","elementType":"all","stylers":[{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1},{"color":"#ace8d3"},{"visibility":"off"},{"weight":"1.94"}]},{"featureType":"poi","elementType":"labels.text","stylers":[{"color":"#4b6376"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"hue":"#ffc200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","elementType":"all","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]}]
        });
        var marker = new google.maps.Marker({
            position: center,
            map: map,
            icon: icon,
            optimized: false
        });
    }
    google.maps.event.addDomListener(window, "load", initMap);
};
