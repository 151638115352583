module.exports = function(){

    var throttle = require('lodash.throttle');
    var lazyBackground = {
        backgroundClass: '.js-lazy-background',
        handleScroll() {
            var self = this;
            var scrolled = $(window).scrollTop();
            var hiddenElements = $(this.backgroundClass + '[data-lazy]');
            hiddenElements.each(function(index, hiddenElement) {
                var element = $(hiddenElement);
                element.css('opacity', 0);
                var windowHeight = $(window).height();
                var offsetTop = element.offset().top;
                if(scrolled + windowHeight > offsetTop + 100) {
                    var backgroundSrc = element.attr('data-lazy');
                    element.css('background-image', 'url(' + backgroundSrc + ')');

                    $('<img/>').attr('src', backgroundSrc).on('load', function() {
                        $(this).remove();
                        element.removeAttr('data-lazy');
                        element.animate({
                            opacity: 1,
                        }, 300);
                    });
                }
            });
        },
        init() {
            var self = this;
            var background = $(this.backgroundClass);
            if(background.length) {
                // throttle scrolling with lodash for better performance
                window.addEventListener('scroll', throttle(function() {
                    self.handleScroll();
                }, 1000, { trailing: true, leading: true }));
                $(window).on('load resize complete', function() {
                    self.handleScroll();
                });
            }
        }
    };
    
    lazyBackground.init();
};