module.exports = function(){
    
    const bodyScrollLock = require('body-scroll-lock');

    var modal = {
        trigger: $('.js-modal-trigger'),
        wrapper: $('.js-modal'),
        content: $('.js-modal-content'),
        image: $('.js-modal-image'),
        closeButton: $('.js-modal-close'),
        close(modalID) {
            var currentModal = $('.js-modal[id="' + modalID + '"]');
            var video = currentModal.find('iframe');
            var content = currentModal.find(this.content);
            currentModal.removeClass('is-open');
            bodyScrollLock.enableBodyScroll(content.get(0));
            if(video.length) {
                video.each(function() {
                    this.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
                });
            }
        },
        open(modalID) {
            var currentModal = $('.js-modal[id="' + modalID + '"]');
            var video = currentModal.find('iframe');
            var content = currentModal.find(this.content);
            currentModal.addClass('is-open');
            bodyScrollLock.disableBodyScroll(content.get(0));
            if(video.length) {
                video.each(function() {
                    this.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
                });
            }
        },
        appendData() {
            modal.wrapper.each(function() {
                var video = $(this).find('iframe');
                if(video.length) {
                    $(video).attr('src', $(video).attr('src') + '?enablejsapi=1&showinfo=0&modestbranding=1&rel=0&controls=0');
                }
            });
        },
        init() {
            modal.appendData();
            modal.trigger.click(function(e) {
                var modalID = $(this).attr('href').split('#')[1];
                modal.open(modalID);
                e.preventDefault();
            });
            modal.closeButton.click(function(e) {
                var modalID = $(this).parents('.js-modal').attr('id');
                modal.close(modalID);
                e.preventDefault();
            });
        }
    };

    modal.init();
};