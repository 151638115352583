module.exports = function(){

    var accordion = {
        triggerClass: '.js-accordion-trigger',
        accordionClass: '.js-accordion',
        contentClass: '.js-accordion-content',
        scrollToContent(accordion) {
            setTimeout(function() {
                $('html, body').animate({
                    scrollTop: accordion.offset().top
                }, 600, 'swing');
                return false;
            }, 320);

            // Prevent scrollTop from messing up user scrolling
            $(window).bind('mousewheel', function() {
                $('html, body').stop();
            });
        },
        closeAccordion(currentTrigger) {
            var accordion = $(this.accordionClass),
                content = accordion.children(this.contentClass),
                currentAccordion = currentTrigger.parent(this.accordionClass),
                currentContent = currentAccordion.children(this.contentClass);

            accordion.removeClass('is-open');
            content.velocity('slideUp', {duration: 300, easing: 'ease'});

        },
        openAccordion(currentTrigger) {
            var currentAccordion = currentTrigger.parent(this.accordionClass),
                currentContent = currentAccordion.children(this.contentClass),
                accordion = $(this.accordionClass),
                content = accordion.children(this.contentClass);

            // Close the content when clicking another accordion trigger
            // if there's already one open
            accordion.removeClass('is-open');
            content.velocity('slideUp', {duration: 300, easing: 'ease'});
            
            currentAccordion.addClass('is-open');
            currentContent.velocity('slideDown', {duration: 200, easing: 'ease'});

            // this.scrollToContent(currentTrigger);

        },
        handleClick(currentTrigger) {
            // Close the current accordion if it's open
            if(currentTrigger.parent(this.accordionClass).hasClass('is-open')) {
                this.closeAccordion(currentTrigger);

                // Prevent further code from executing
                return false;
            };
            // Open the accordion
            this.openAccordion(currentTrigger);
        },
        init() {
            var self = this;
            $(this.triggerClass).on('click', function(event) {
                event.stopPropagation();
                var currentTrigger = $(this);
                self.handleClick(currentTrigger);
            });
        }
    };

    accordion.init();

};