module.exports = function(){
    
    const bodyScrollLock = require('body-scroll-lock');

    var mobileMenu = {
        menuToggleClass: '.js-mobile-menu-toggle',
        menuClass: '.js-mobile-menu',
        styleChildren() {
            var transitionDelay = 0.2;
            $('.mobile-menu__item').each(function(index, item) {
                $(item).css('transition-delay', function() {
                    transitionDelay += 0.09;
                    return transitionDelay + 's';
                });
            });
        },
        updateClass() {
            var menu = $(this.menuClass);
            $('html').toggleClass('is-mobile-menu-open');
            if($('html').hasClass('is-mobile-menu-open')) {
                bodyScrollLock.disableBodyScroll(menu.get(0));
            } else {
                bodyScrollLock.enableBodyScroll(menu.get(0));
            }
        },
        init() {
            var self = this;
            var menuToggle = $(this.menuToggleClass);
            this.styleChildren();
            menuToggle.on('click', function(e) {
                self.updateClass();
                e.preventDefault();
            });
        }
    }

    mobileMenu.init();
};